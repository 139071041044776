const enMessages = {
  'menu.dashboards': 'Dashboards',
  'menu.default': 'Default',
  'menu.visual': 'Visual',
  'menu.views': 'List',
  'menu.analytic': 'Analytic',
  'menu.apps': 'Apps',
  'menu.calendar': 'Calendar',
  'menu.chat': 'Chat',
  'menu.contacts': 'Contacts',
  'menu.mailbox': 'Mailbox',
  'menu.tasks': 'Tasks',
  'menu.pages': 'Pages',
  'menu.authentication': 'Authentication',
  'menu.login': 'Login',
  'menu.register': 'Register',
  'menu.forgot-password': 'Forgot Password',
  'menu.reset-password': 'Reset Password',
  'menu.blog': 'Blog',
  'menu.statements': 'Statements',
  'menu.home': 'Home',
  'menu.grid': 'Grid',
  'menu.catalog': 'Catalog',
  'menu.warehouse': 'Warehouse',
  'menu.studio': 'Studio',
  'menu.sales': 'Sales',
  'menu.company_catalog': 'Catalog',
  'menu.company_categories': 'Categories',
  'menu.fields': 'Fields',
  'menu.field_sets': 'Field Sets',
  'menu.exports': 'Exports',
  'menu.brand_management': 'Brand Management',
  'menu.activity': 'Audit Logs',
  'menu.magentoSync': 'Magento Sync',
  'menu.product-feed': 'Product Feed',
  'menu.sellers': 'Sellers',
  'menu.ratings-and-reviews': 'Rating and Reviews',
  'menu.question-and-answer': 'Question and Answer',
  'menu.import': 'Import',
  'menu.import_history': 'Import History',
  'menu.quick_links': 'Quick Links',
  'menu.pending_approval': 'New Products Pending QC',
  'menu.products_pending_qc': 'Products Pending QC',
  'menu.attributes_pending_qc': 'Attributes Pending QC',
  'menu.pending_products': 'Pending Products',
  'menu.pending_media_approval': 'New Products Media QC',
  'menu.existing_media_approval': 'Pending Media QC',
  'menu.product_media': 'Product Images',
  'menu.rejected_catalog': 'Rejected (10)',
  'menu.low_inventory': 'Low in Inventory (10)',
  'menu.out_of_stock_items': 'Out of stock (50)',
  'menu.reports': 'Reports',
  'menu.detail': 'Detail',
  'menu.miscellaneous': 'Miscellaneous',
  'menu.faq': 'Faq',
  'menu.knowledge-base': 'Knowledge Base',
  'menu.error': 'Error',
  'menu.coming-soon': 'Coming Soon',
  'menu.pricing': 'Pricing',
  'menu.search': 'Search',
  'menu.mailing': 'Mailing',
  'menu.empty': 'Empty',
  'menu.portfolio': 'Portfolio',
  'menu.profile': 'Profile',
  'menu.standard': 'Standard',
  'menu.settings': 'Settings',
  'menu.blocks': 'Blocks',
  'menu.images': 'Images',
  'menu.tabular-data': 'Tabular Data',
  'menu.thumbnails': 'Thumbnails',
  'menu.cta': 'Cta',
  'menu.gallery': 'Gallery',
  'menu.stats': 'Stats',
  'menu.steps': 'Steps',
  'menu.details': 'Details',
  'menu.interface': 'Interface',
  'menu.components': 'Components',
  'menu.accordion': 'Accordion',
  'menu.alerts': 'Alerts',
  'menu.badge': 'Badge',
  'menu.breadcrumb': 'Breadcrumb',
  'menu.buttons': 'Buttons',
  'menu.button-group': 'Button Group',
  'menu.card': 'Card',
  'menu.close-button': 'Close Button',
  'menu.collapse': 'Collapse',
  'menu.dropdowns': 'Dropdowns',
  'menu.list-group': 'List Group',
  'menu.modal': 'Modal',
  'menu.navs': 'Navs',
  'menu.offcanvas': 'Offcanvas',
  'menu.pagination': 'Pagination',
  'menu.popovers': 'Popovers',
  'menu.progress': 'Progress',
  'menu.spinners': 'Spinners',
  'menu.toasts': 'Toasts',
  'menu.tooltips': 'Tooltips',
  'menu.forms': 'Forms',
  'menu.layouts': 'Layouts',
  'menu.validation': 'Validation',
  'menu.wizard': 'Wizard',
  'menu.input-group': 'Input Group',
  'menu.input-mask': 'Input Mask',
  'menu.generic-forms': 'Generic Forms',
  'menu.controls': 'Controls',
  'menu.autocomplete': 'Autocomplete',
  'menu.checkbox-radio': 'Checkbox-Radio',
  'menu.date-picker': 'Date Picker',
  'menu.dropzone': 'Dropzone',
  'menu.editor': 'Editor',
  'menu.input-spinner': 'Input Spinner',
  'menu.rating': 'Rating',
  'menu.select': 'Select',
  'menu.slider': 'Slider',
  'menu.tags': 'Tags',
  'menu.time-picker': 'Time Picker',
  'menu.plugins': 'Plugins',
  'menu.carousel': 'Carousel',
  'menu.charts': 'Charts',
  'menu.clamp': 'Clamp',
  'menu.context-menu': 'Context Menu',
  'menu.datatables': 'Datatables',
  'menu.editable-rows': 'Editable Rows',
  'menu.editable-boxed': 'Editable Boxed',
  'menu.server-side': 'Server Side',
  'menu.boxed-variations': 'Boxed Variations',
  'menu.lightbox': 'Lightbox',
  'menu.maps': 'Maps',
  'menu.notification': 'Notification',
  'menu.players': 'Players',
  'menu.scrollbar': 'Scrollbar',
  'menu.shortcuts': 'Shortcuts',
  'menu.sortable': 'Sortable',
  'menu.content': 'Content',
  'menu.icons': 'Icons',
  'menu.cs-line-icons': 'CS Line Icons',
  'menu.cs-interface-icons': 'CS Interface Icons',
  'menu.bootstrap-icons': 'Bootstrap Icons',
  'menu.tables': 'Tables',
  'menu.typography': 'Typography',
  'menu.menu': 'Menu',
  'menu.horizontal': 'Horizontal',
  'menu.vertical': 'Vertical',
  'menu.vertical-hidden': 'Vertical Hidden',
  'menu.vertical-no-hidden': 'Vertical No Hidden',
  'menu.mobile-only': 'Mobile Only',
  'menu.sidebar': 'Sidebar',
  'menu.Docs': 'Docs',
  'menu.connections': 'Connections',
  'menu.bookmarks': 'Bookmarks',
  'menu.requests': 'Requests',
  'menu.account': 'Account',
  'menu.accounts': 'Accounts',
  'menu.password': 'Password',
  'menu.devices': 'Devices',
  'menu.notifications': 'Notifications',
  'menu.email': 'Email',
  'menu.sms': 'SMS',
  'menu.downloads': 'Downloads',
  'menu.documents': 'Documents',
  'menu.videos': 'Videos',
  'menu.dashboard': 'Dashboard',
  'menu.products': 'Products',
  'menu.all_products': 'All Products',
  'menu.orders': 'Orders',
  'menu.promotions': 'Promotions',
  'menu.promotions-auto-applied': 'Product Promotions',
  'menu.combo-offers': 'Combo Offers',
  'menu.coupons': 'Coupons',
  'menu.couponLogs': 'Coupon Logs',
  'menu.customers': 'Customers',
  'menu.storefront': 'Storefront',
  'menu.filters': 'Filters',
  'menu.categories': 'Categories',
  'menu.cart': 'Cart',
  'menu.checkout': 'Checkout',
  'menu.invoice': 'Invoice',
  'menu.shipping': 'Shipping',
  'menu.discount': 'Discount',
  'menu.general': 'General',
  'menu.upgrade': 'Upgrade',
  'menu.community': 'Community',
  'menu.community-list': 'Community List',
  'menu.getting-started': 'Getting Started',
  'menu.analysis': 'Analysis',
  'menu.messages': 'Messages',
  'menu.sales_analysis': 'Sales Analysis',
  'menu.product_analysis': 'Product Analysis',
  'menu.order_monitoring': 'Order Monitoring',
  'menu.product_monitoring': 'Product Monitoring',
  'menu.studio-images': 'Studio Images',
  'menu.unlisted-products': 'Unlisted Products',

  'menu.services': 'Services',
  'menu.database': 'Database',
  'menu.database-add': 'New Database',
  'menu.database-detail': 'Database Detail',
  'menu.storage': 'Storage',
  'menu.hosting': 'Hosting',
  'menu.users': 'Users',
  'menu.billing': 'Billing',
  'menu.security': 'Security',
  'menu.support': 'Support',
  'menu.docs': 'Docs',
  'menu.docsdetail': 'Docs Detail',
  'menu.tickets': 'Tickets',
  'menu.tickets-detail': 'Teicket Detail',
  'menu.elearning': 'Elearning',
  'menu.school': 'School',
  'menu.courses': 'Courses',
  'menu.explore': 'Explore',
  'menu.quiz': 'Quiz',
  'menu.result': 'Result',
  'menu.paths': 'Paths',
  'menu.instructor': 'Instructor',
  'menu.misc': 'Miscellaneous',
  'menu.player': 'Player',
  'menu.material': 'Material',
  'menu.syllabus': 'Syllabus',
  'menu.sortby': 'Sort By',
  'menu.offers': 'Offers',
  'sort.name_asc': 'Name (Asc)',
  'sort.name_desc': 'Name (Desc)',
  'sort.sku_asc': 'Sku (Asc)',
  'sort.sku_desc': 'Sku (Desc)',
  'sort.qty_asc': 'Qty (Asc)',
  'sort.qty_desc': 'Qty (Desc)',
  'sort.price_asc': 'Price (Asc)',
  'sort.price_desc': 'Price (Desc)',

  'sort.date_asc': 'Date (Asc)',
  'sort.date_desc': 'Date (Desc)',
  'sort.status_asc': 'Status (Asc)',
  'sort.status_desc': 'Status (Desc)',

  'heading.products': 'Products',
  'button.create_product': 'Create Product',
  'button.save_and_apply_filter': 'Save & Apply Filter',
  'button.delete_filter': 'Delete Filter',
  'button.apply_filter': 'Apply Filter',
  'button.create_offer': 'Create Offer',
  'button.add_new_user': 'Add New User',

  // seller
  'seller.list': 'Seller Accounts',
  'seller.audit': 'Seller Audits',

  // inventory
  'menu.inventory': 'Inventory',
};
export default enMessages;
